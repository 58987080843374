<template>
    <div>
        <div class="box">
            <div class="d-flex">
                <div class="flex-auto">
                    <UserSelector @changeUser="updateSelectedUser" />
                </div>
                <div class="flex-auto">
                    <ProjectSelector
                        v-if="selectedUser"
                        :user-id="selectedUser"
                        @changeProject="updateSelectedProject"
                    />
                </div>
            </div>
        </div>
        <b-notification v-if="!selectedUser || !selectedProject" :closable="false" type="is-info is-light">
            Veuillez selectionner un utilisateur et un projet
        </b-notification>
        <div v-else class="box">
            <h3>Liste des sites disponibles</h3>
            <DomainPostingTable :project-id="selectedProject" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DomainPosting',
        title: 'domain-posting.title',
        data() {
            return {
                selectedUser: null,
                selectedProject: null
            }
        },
        methods: {
            updateSelectedUser(user) {
                this.selectedUser = user
            },
            updateSelectedProject(project) {
                this.selectedProject = project
            }
        }
    }
</script>

<style scoped>
    .flex-auto {
        display: flex;
        flex: 1 1 0;
    }

    .d-flex {
        gap: 2rem;
    }
</style>
